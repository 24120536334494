import React, {FC} from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import QRGeneratorWrapper from '../../../components/tools-components/qr-generator';

const QRGenerator: FC = () => (
  <Layout>
    <PageHero
      title="QR code generator - Create QR codes for any URL, text or number"
      titleSmall
      subtitle="Generate QR codes in seconds that can be used on stationary, posters, merchandise or product packaging."
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto w-100">
      <QRGeneratorWrapper />
    </Section>{' '}
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 text-center">QR code generator FAQ</h4>

        <FAQItem
          title="What is a QR Code?"
          text={
            <>
              <p>
                A QR (quick response) code is a type of barcode, containing data for a locator, identifier, or tracker,
                pointing to an application or a website. QR codes were initially developed by Japanese automotive
                company Denso Wave to mark components for accelerating logistics processes.
              </p>
              <p>
                Today, QR codes are everywhere, being widely used for tracking information about products/services, for
                marketing campaigns, and many more. You can see QR codes everywhere: on product packaging, restaurant
                menus, tickets, etc.{' '}
              </p>
            </>
          }
        />
        <FAQItem
          title="What kind of information can be stored in a QR Code?"
          text={
            <p>With our QR code generator tool, you can create QR codes from any URL, number, or text in seconds.</p>
          }
        />
        <FAQItem
          title="What are the benefits of using QR Codes?"
          text={
            <>
              <p>
                QR codes are pretty versatile. You can easily link various engaging content to QR codes to promote your
                business.
              </p>
              <p>
                QR codes can instantly send information about any product/service directly to the user's device. They
                are ideal for boosting interaction and engagement. Additionally, QR codes are trackable, allowing you to
                understand the marketing campaign by providing comprehensive information about your target audience.
              </p>
              <p>
                Another advantage of QR codes is that they can store a larger amount of information as opposed to
                barcodes.
              </p>
              <p>Last but not least, QR codes help you to save on pricing expenses and manpower.</p>
            </>
          }
        />
        <FAQItem
          title="Can I use the generated QR Codes for commercial purposes?"
          text={
            <p>
              Yes. QR codes can be easily used for any commercial purpose, including marketing campaigns, presentation
              of your products and services, product packaging, merchandising, implementing contactless payments, and
              more. With QR codes, your customers will be able to get more information than offline.
            </p>
          }
        />
        <FAQItem
          title="How long are QR Codes valid for? Do they expire?"
          text={<p>QR codes don’t have an expiration date. They last as long as the quick link is active.</p>}
        />
        <FAQItem
          title="How do I scan QR codes?"
          text={
            <p>
              It depends on your device. On most mobile devices, it is possible to scan QR codes by opening the camera
              application and holding over the QR code for seconds. If that doesn’t work, means that QR code scanning is
              disabled on the given mobile device. Go and enable it. If the scanning doesn't work, after all, you can
              use third-party QR code readers.
            </p>
          }
        />
      </div>
    </Section>
  </Layout>
);

export default QRGenerator;
