import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture1 from '../../../images/tools/qr-generator/one.png';
import picture2 from '../../../images/tools/qr-generator/two.png';
import picture3 from '../../../images/tools/qr-generator/three.png';

const Info = () => (
  <div className="qr-generator-info row mt-0 mx-0 mb-3">
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Drive traffic and increase sales using a QR code generator</h4>
      <div className="mb-2">
        No one likes typing website URLs, with a QR code anyone can reach your website or promotion by scanning the code
        with their mobile device.
      </div>
      <div className="mb-4">
        To get started, enter your URL and print the generated QR code on your business cards, stationary or product
        packaging.
      </div>

      <div>
        <Button className="px-0" onClick={() => navigate('/registration')}>
          Sign up for Hexomatic account to increase your limits
        </Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture1} alt="Grammar &amp; spelling page audit" />
    </div>

    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-3">
      <img src={picture2} alt="Grammar &amp; spelling page audit" />
    </div>

    <div className="col-12 col-md-6 mb-5">
      <h4 className="mb-4">Make your offline printed content dynamic with QR codes</h4>
      <div className="mb-2">
        QR (Quick Response) codes are special barcodes that can make any printed page dynamic, providing 1 click access
        to your:
      </div>
      <ul className="p-0">
        <li>Social media profile</li>
        <li>Website address</li>
        <li>Restaurant menu</li>
        <li>Product documentation</li>
        <li>Return address</li>
        <li>Documentation</li>
        <li>Phone number</li>
      </ul>
      <div className="mb-2">
        All users have to do is use their phone app or a QR code scanner such as Google Lense to take a picture of the
        code and be directed to the linked resource.
      </div>
      <div className="mb-4">
        After generating a QR code, you can share it online through social media, websites, business cards or product
        packaging.
      </div>
    </div>

    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Create QR codes at scale with Hexomatic</h4>
      <div className="mb-2">
        Hexomatic is a comprehensive work automation platform that enables you to generate high resolution QR codes at
        scale and tap into 100+ ready-made automations to automate sales, marketing, and research tasks in minutes.
      </div>
      <div className="mb-2">
        To get started Insert any type of content, and our Hexomatic QR code generator will turn it into a QR code on
        autopilot. You can use the generated QR codes for commercial purposes and print the barcode on stationary,
        posters, merchandise or product packaging.
      </div>
      <div className="mb-4">Hexomatic works 24/7 from the cloud, no complex software or coding is required.</div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={picture3} alt="Grammar &amp; spelling page audit" />
    </div>
  </div>
);

export default Info;
