import React, {FC} from 'react';
import {Link, navigate} from 'gatsby';
// import FileSaver from 'file-saver';

import NothingToShow from '../../nothingToShow';
import {downlodIconBtn} from '../../../images/svgIcons';
import {cleanShortURL, makeValidHref} from '../../../helpers';
import {isValidUrl} from '../../../helpers/constants';

import './styles.scss';

interface IAppProps {
  data: any;
  url?: string;
  scanUrl?: string;
}

const App: FC<IAppProps> = props => {
  if (props.data?._qr_code[0] === 'Not found.') {
    return (
      <div className="mb-5 w-100">
        <span className="go-back" onClick={() => navigate(-1)}>
          ← Go Back
        </span>
        <div className=" d-flex justify-content-center">
          <NothingToShow />
        </div>
      </div>
    );
  }

  const handleClick = () => {
    const url = props.data?._qr_code[0].replace('///', '/');
    // url && FileSaver.saveAs(url, `${cleanShortURL(props.scanUrl || props.url || '')}-qr.png`);
    url && window.open(url, '_blank', 'noopener');
  };

  return (
    <div className="w-100">
      {props.url && (
        <div className="d-flex m-auto pb-2" style={{maxWidth: '741px', width: '90%'}}>
          <div>
            <Link to="/tools/history/#qr-code-generator">← Back to history</Link>
          </div>
          <div className="ml-auto" style={{maxWidth: 'calc(100% - 150px)', whiteSpace: 'nowrap', display: 'flex'}}>
            <div className="mr-1">Result for</div>
            {isValidUrl(props.url) ? (
              <a
                title={props.url}
                href={makeValidHref(props.url)}
                target="_blank"
                className="text-truncate"
                style={{width: 'calc(100% - 50px)', display: 'inline-block'}}
                rel='noopener'
              >
                {props.url}
              </a>
            ) : (
              <span
                title={props.url}
                className="text-truncate qr-span"
                style={{width: 'calc(100% - 50px)', display: 'inline-block'}}
              >
                {props.url}
              </span>
            )}
          </div>
        </div>
      )}
      <div className="qr-result-wrapper">
        <div className="qr-code-image-wrapper">
          <img src={props.data?._qr_code[0]} alt="QR code" />
        </div>
        <div className="pt-3 pb-4">
          <p
            className={`d-flex align-items-center justify-content-center download-btn mt-2 mb-2`}
            onClick={handleClick}
          >
            {downlodIconBtn} <span className="ml-2">Download PNG</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;
